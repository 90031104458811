import { useEffect } from 'react';

import './Ads.css';

const AD_CLIENT = "ca-pub-6626303779279416"
const AD_SLOT = "3287035162"
const AD_FORMAT = "auto"

const Adsense = ({ adClient, adSlot, adFormat }) => {
  useEffect(() => {
    if (window.adsbygoogle) {
      try {
        window.adsbygoogle.push({});
      } catch (e) {
        console.error("AdSense error:", e);
      }
    }
  }, []);

  return (
    <ins className="adsbygoogle"
      data-adtest="on"
      style={{ display: 'block' }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format={adFormat}>
    </ins>
  )
};

export const Ads = () => {
  return (
    <div className="ads_body">
      <div className='ads_top'>
        <Adsense adClient={AD_CLIENT} adSlot={AD_SLOT} adFormat={AD_FORMAT} />
      </div>
      <div className="ads_header">
        <h1>adverts</h1>
      </div>
      <div className='ads_main'>
        <h3 className='sad'>This page was meant to be absolutely riddled with adverts but google adsense said it didn't contain enough 'content' to put ads on :-(</h3>
        <Adsense adClient={AD_CLIENT} adSlot={AD_SLOT} adFormat={AD_FORMAT} />
      </div>
    </div>
  );
};


